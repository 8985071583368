var fixedheader = {
    init: function(){

        var screenwidth = $(window).outerWidth()
        var headerheight = $('header').outerHeight();
		   
 		if (screenwidth >= 768) {  
			$('.contentwrapper').css('margin-top',headerheight);   
			$(window).bind('scroll', function () {
				var num = 450;
				if ($(window).scrollTop() > num) {
					$('header').addClass('shrink');
				} else {
					$('header').removeClass('shrink');
				}
			});
		} 
		else { 
			$('header').removeClass('shrink');
			$('.contentwrapper').css('margin-top','inherit');   
		} 
    }
}